
export const enterRightAnimation ={
    from: {  transform: 'translateX(200%)' },
    to: {transform: 'translateX(0%)' },
    config: { duraton:400 ,tension: 220, friction: 30 },
     delay: 350 ,
  }
export const enterLeftAnimation ={
    from: {  transform: 'translateX(-200%)' },
    to: {  transform: 'translateX(0%)' },
    config: { duraton:400 ,tension: 220, friction: 30 },
     delay: 350 ,
  }